<template>
    <div ref="candidateDataView" id="candidateDataView">
        <div class="row action-toolbar-container" id="taxroll-list-table-header-section">
            <div class="col-10" v-if="!loading">
                <Button :disabled="totalRecords <= 0" v-if="!selectAllCurrentPage" id="select-all" type="button"
                    outlined class="me-2" @click="onSelectAllChange()" severity="secondary">
                    <span class="fa fa-check-square me-1" aria-hidden="true"></span>
                    Select All
                </Button>
                <Button v-else id="deselect-all" type="button" outlined class="me-2" @click="resetToInitialState()"
                    severity="secondary">
                    <span class="fa fa-square-minus me-1" aria-hidden="true"></span>
                    Clear Selection
                </Button>
                <SplitButton v-if="!['archive', 'unreleased_ir'].includes(props.queue)" label="Update Queue" rounded
                    class="me-2" :model="updateQueueActionItems" id="queue-action-item"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)"
                    @click="selectQueueUpdateAction(defaultQueueUpdateForm)">
                    <span class="fa fa-tasks me-1" aria-hidden="true"></span>
                    Update Queue
                </SplitButton>
                <SplitButton label="Assign" rounded class="me-2" :model="assignActionItems" id="assign-action-item"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)"
                    @click="onBulkActionClick('Assign')">
                    <span class="fa fa-user-plus me-1" aria-hidden="true"></span>
                    Assign
                </SplitButton>
                <SplitButton label="Labels" rounded class="me-2" :model="labelActionItems" id="label-action-item"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)"
                    @click="onBulkActionClick('Add Label')" severity="info">
                    <span class="fa fa-tags me-1" aria-hidden="true"></span>
                    Labels
                </SplitButton>
                <Button type="button" class="me-2" id="note-action-item" @click="onBulkNoteClick" severity="info"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)">
                    <span class="fa fa-comment me-1" aria-hidden="true"></span> Note
                </Button>
                <span v-if="props.queue === 'unreleased_ir'">
                    <Button type="button" class="me-2" id="release-ir-action-item" @click="onBulkIRClick('release')"
                        severity="info" :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)">
                        <span class="fa fa-file-alt me-1" aria-hidden="true"></span> Release
                    </Button>
                    <Button type="button" class="me-2" id="delete-ir-action-item" @click="onBulkIRClick('delete')"
                        severity="info" :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)">
                        <span class="fa fa-file-excel me-1" aria-hidden="true"></span> Delete
                    </Button>
                </span>
            </div>
            <div class="col-10" v-else>
                <Skeleton width="115px" height="34px" class="ms-2 mb-2"></Skeleton>
            </div>
            <div class="col-2">
                <div class="float-end">
                    <StrapCardHeaderActions :filterConfig="filterConfig" :loading="loading" :sortOrder="sortOrder"
                        :parentDataView="candidateDataView" @changeSortOrder="changeSortOrder"
                        @submitFilters="handleSubmitFilters" @updateFilters="handleUpdateFilters"
                        @onFiltersDirtyChanged="onFiltersDirtyChanged" ref="strapCardHeaderActionsRef" />
                </div>
            </div>
        </div>

        <div class="d-block text-center" id="appvet-table-header-select-all-message" v-if="selectAllCurrentPage">
            <Message severity="secondary" :closable="false">
                <span v-if="!selectAll">
                    All {{ formatNumberWithCommas(totalSelectedCandidate) }} applications on this
                    page are selected.
                    <a v-if="!selectAll && (dataSelection.length < totalRecords)" href="javascript:;"
                        @click="() => selectAll = true">
                        Select all {{ formatNumberWithCommas(totalRecords) }} applications in this
                        tab
                    </a>
                </span>
                <span v-else>
                    All {{ formatNumberWithCommas(totalSelectedCandidate) }} applications are
                    selected.
                    <a v-if="selectAll" href="javascript:;" @click="resetToInitialState()">
                        Clear Selection
                    </a>
                </span>
            </Message>
        </div>

        <div v-if="loading">
            <div v-for="i in 5" :key="i" class="row candidate-list-row-card">
                <div class="col align-content-center">
                    <div class="p-4">
                        <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="70%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="40%" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col-2 align-content-center">
                    <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="60%" height="1rem" class="mb-2"></Skeleton>
                </div>
                <div class="col-2 align-content-center">
                    <Skeleton width="50%" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                </div>
                <div class="col-2 align-content-center">
                    <div class="p-3">
                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                        <div class="d-flex mb-2">
                            <Skeleton width="50%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="80%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="30%" height="1rem" class="me-2"></Skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-2 align-content-center">
                    <div class="p-3 d-flex">
                        <Skeleton width="100%" height="4rem" class="me-2"></Skeleton>
                    </div>
                </div>
            </div>
        </div>

        <BasicStrapCards :loading="loading" :data="data" :firstIndex="first" :rows="rows" :componentID="props.queue"
            :totalRecords="totalRecords" @onpage="(event) => onPage(event)" :parentDataView="candidateDataView">
            <template #list="slotProps">
                <div v-for="(item, index) in (slotProps.data as CandidateListNew[])" :key="index"
                    class="row dataview-list-row-card">
                    <div class="col-1 align-content-center" id="selectionColumn">
                        <div class="p-3">
                            <Checkbox v-model="dataSelection" :inputId="item.tru_id" name="category" :value="item" />
                        </div>
                    </div>
                    <div class="col align-content-center clipboard-copy-wrapper">
                        <div class="p-3">
                            <div class="mb-2">
                                <span>
                                    <a :href="`/candidate-details/${item.tru_id}`" target="_blank">
                                        {{ item.parcel_num }}
                                    </a>
                                </span>
                                <span class="clipboard-copy-target" :id="item?.tru_id || ''"
                                    :data-copy-text="item?.parcel_num"></span>
                            </div>
                            <div class="mb-2">
                                {{ item.situs }}
                            </div>
                            <div v-if="item?.owners?.length">
                                <div class="mb-2" v-for="(owner, index) in item.owners" :key="index">
                                    {{ owner }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <div v-if="item?.exemptions?.length">
                                <div class="mt-2" v-for="(exemption, index) in item.exemptions" :key="index">
                                    {{ exemption }}
                                </div>
                            </div>
                            <div v-if="item.effective_date" class="mt-2">
                                Effective {{ formatDate(item.effective_date) }}
                            </div>
                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <div v-if="item.last_queue_change" class="mb-2">
                                <span v-tooltip="formatDate(item.last_queue_change)">
                                    {{ dayjs(item.last_queue_change).fromNow() }}
                                </span>
                            </div>
                            <div class="mb-2" v-if="props.queue === 'inbox'">
                                <div v-if="timeDiffForHumans(item.updated_on, 'day')">
                                    Updated {{ timeDiffForHumans(item.updated_on, 'day') }} days ago
                                </div>
                                <div v-else>Less than a day</div>
                            </div>
                            <div
                                v-if="['questionnaire', 'unqualified', 'snooze', 'monitor', 'archive', 'all', 'unreleased_ir'].includes(props.queue)">
                                <div class="mb-2 text-capitalize">Moved from: {{ item.prev_queue }}</div>
                                <div class="mb-2" v-if="item.last_queue_change_user">By: {{
                                    item.last_queue_change_user }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <span v-if="item.assigned_user_id">
                                <Chip :label="item.assigned_user" class="me-2 mb-2 tr-chip assigned-to-chip" />
                            </span>
                            <span v-else class="mb-2">
                                <i>Unassigned</i>
                            </span>
                            <div v-if="item.labels" class="d-block mt-2">
                                <span v-for="(label, index) in item.labels" :key="index">
                                    <Chip :label="label" class="me-2 mb-2 tr-chip label-chip" />
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <div class="candidate-list-img-container"
                                v-if="['inbox', 'monitor', 'unreleased_ir'].includes(props.queue)">
                                <div v-if="item.released_ir">
                                    <img :src="RELEASED_IR_GRAPIC_URL" alt="Priority Score">
                                </div>
                                <div v-else>
                                    <img :src="getPriorityScoreGraphicUrl(item.priority_score)" alt="Priority Score">
                                </div>
                            </div>
                            <div v-else-if="props.queue === 'questionnaire'">{{ item.mailing_template }}</div>
                            <div v-else-if="props.queue === 'unqualified'">
                                <div class="mb-2">
                                    {{ formatter.format(item.recovered_amount as unknown as number) }}
                                </div>
                                <div class="mb-2">{{ item.note }}</div>
                            </div>
                            <div v-else-if="props.queue === 'snooze'">
                                <div class="mb-2" :class="{ 'fw-bold': isSnoozeExpired(item.snooze_until) }">Snoozed
                                    Until {{ formatDate(item.snooze_until) }}</div>
                            </div>
                            <div v-else-if="props.queue === 'archive'">
                                <div class="mb-2 text-capitalize">{{ item.final_outcome }}</div>
                            </div>
                            <div v-else-if="props.queue === 'released_ir'">
                                <img :src="RELEASED_IR_GRAPIC_URL" alt="Released IR">
                            </div>
                            <div v-else>
                                <div class="mb-2 text-capitalize">{{ getQueueText(item.queue) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BasicStrapCards>

        <div id="application-vetter-modals">
            <BulkActionModalDialog :visible="bulkAssign.showAssignDialog.value"
                :dialogTitle="`Assign ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeAssignDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Select v-model="bulkAssign.user.value" :options="userList" optionLabel="name"
                        placeholder="-- Assign User --" class="d-flex my-2" />
                    <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkAssign('assign')" id="bulk-assign" :disabled="bulkAssign.loading.value">
                        <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true"
                            v-if="bulkAssign.loading.value"></span>
                        Assign
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkAssign.showUnassignDialog.value"
                :dialogTitle="`Unassign ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeAssignDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkAssign('unassign')" id="bulk-unassign"
                        :disabled="bulkAssign.loading.value" severity="warning">
                        <span class="fa fa-user-times me-2" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true"
                            v-if="bulkAssign.loading.value"></span>
                        Unassign
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkAssign.showAssignToMeDialog.value"
                :dialogTitle="`Assign ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate} to me`"
                :closeDialog="closeAssignDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkAssign('assigntome')" id="bulk-assign-to-me"
                        :disabled="bulkAssign.loading.value">
                        <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true"
                            v-if="bulkAssign.loading.value"></span>
                        Assign To Me
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkLabel.showAddDialog.value"
                :dialogTitle="`Add labels to ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeLabelDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
                        v-model="bulkLabel.add.value" multiple optionLabel="label_name" :options="labelList"
                        class="d-flex my-2" />
                    <Textarea v-model="bulkLabel.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkLabel('add')" id="add-bulk-label" :disabled="bulkLabel.loading.value">
                        <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!bulkLabel.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true"
                            v-if="bulkLabel.loading.value"></span>
                        Add
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkLabel.showRemoveDialog.value"
                :dialogTitle="`Remove labels from ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeLabelDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
                        v-model="bulkLabel.remove.value" multiple optionLabel="label_name" :options="labelList"
                        class="d-flex my-2" />
                    <Textarea v-model="bulkLabel.optionalNote.value" :maxlength="MAX_NOTE_LENGTH"
                        placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkLabel('remove')" id="remove-bulk-label" severity="warning"
                        :disabled="bulkLabel.loading.value">
                        <span class="fa fa-trash me-2" aria-hidden="true" v-if="!bulkLabel.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true"
                            v-if="bulkLabel.loading.value"></span>
                        Remove
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkNote.showNoteDialog?.value"
                :dialogTitle="`Add note to ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate}`"
                :closeDialog="closeNoteDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Textarea v-model="bulkNote.note.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkNote" id="bulk-note" :disabled="bulkNote.loading.value">
                        <span class="fa fa-edit me-2" aria-hidden="true" v-if="!bulkNote.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true"
                            v-if="bulkNote.loading.value"></span>
                        Submit
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkIR.showReleaseIRDialog?.value"
                :dialogTitle="`Release ${formatNumberWithCommas(totalSelectedCandidate)} report(s)`"
                :closeDialog="closeIRDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Message severity="warn" :closable="false" class="my-2">
                        Are you sure you want to release the {{ formatNumberWithCommas(totalSelectedCandidate) }}
                        selected
                        investigation report(s) ? This action cannot be undone.
                    </Message>
                </template>
                <template #footer-button>
                    <Button @click="submitBulkIR('release')" :disabled="bulkIR.loading.value"
                        id="release-ir-action-item">
                        <span class="fa fa-file-alt me-2" aria-hidden="true" v-if="!bulkIR.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkIR.loading.value"></span>
                        Release
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkIR.showDeleteIRDialog?.value"
                :dialogTitle="`Delete ${formatNumberWithCommas(totalSelectedCandidate)} report(s)`"
                :closeDialog="closeIRDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>
                    <Message severity="warn" :closable="false" class="my-2">
                        Are you sure you want to delete the {{ formatNumberWithCommas(totalSelectedCandidate) }}
                        selected
                        investigation report(s) ? This action cannot be undone.
                    </Message>
                </template>
                <template #footer-button>
                    <Button @click="submitBulkIR('delete')" :disabled="bulkIR.loading.value" id="delete-ir-action-item">
                        <span class="fa fa-file-excel me-2" aria-hidden="true" v-if="!bulkIR.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkIR.loading.value"></span>
                        Delete
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkQueue.showQueueDialog?.value"
                :dialogTitle="`Update ${formatNumberWithCommas(totalSelectedCandidate)} ${textCandidate} to ${bulkQueue.queue.value}`"
                :closeDialog="closeQueueDialog" :selectAll="selectAll" :selectAllCurrentPage="selectAllCurrentPage">
                <template #form-fields>

                    <CandidateUpdateQueueInbox v-if="bulkQueue.queue.value === 'inbox'"
                        v-model:optionalNote="bulkQueue.optionalNote.value" />

                    <CandidateUpdateQueueQuestionnaire v-if="bulkQueue.queue.value === 'questionnaire'"
                        v-model:mailingTemplate="bulkQueue.mailingTemplate.value"
                        v-model:optionalNote="bulkQueue.optionalNote.value"
                        :mailingTemplateDropdown="mailingTemplateDropdown" />

                    <CandidateUpdateQueueMonitor v-if="bulkQueue.queue.value === 'monitor'"
                        v-model:reasons="bulkQueue.reason.value" v-model:optionalNote="bulkQueue.optionalNote.value"
                        :reasonsDropdown="reasonsDropdown" />

                    <CandidateUpdateQueueSnooze v-if="bulkQueue.queue.value === 'snooze'"
                        v-model:reasons="bulkQueue.reason.value" v-model:optionalNote="bulkQueue.optionalNote.value"
                        v-model:snoozeUntil="bulkQueue.snoozeUntil.value" :reasonsDropdown="reasonsDropdown" />

                    <CandidateUpdateQueueUnqualified v-if="bulkQueue.queue.value === 'unqualified'"
                        v-model:reasons="bulkQueue.reason.value"
                        v-model:unqualifiedStartYear="bulkQueue.unqualifiedStartYear.value"
                        v-model:mailingTemplate="bulkQueue.mailingTemplate.value"
                        v-model:lienOrBackTaxes="bulkQueue.lienOrBackTaxes.value"
                        v-model:backTaxLienAmount="bulkQueue.backTaxLienAmount.value"
                        v-model:optionalNote="bulkQueue.optionalNote.value"
                        v-model:recoveredAmount="bulkQueue.recoveredAmount.value"
                        :totalCandidateSelected="totalSelectedCandidate"
                        :mailingTemplateDropdown="mailingTemplateDropdown" :reasonsDropdown="reasonsDropdown" />

                    <CandidateUpdateQueueArchive v-if="bulkQueue.queue.value === 'archive'"
                        v-model:finalOutcome="bulkQueue.finalOutcome.value"
                        v-model:unqualifiedStartYear="bulkQueue.unqualifiedStartYear.value"
                        v-model:lienOrBackTaxes="bulkQueue.lienOrBackTaxes.value"
                        v-model:backTaxLienAmount="bulkQueue.backTaxLienAmount.value"
                        v-model:optionalNote="bulkQueue.optionalNote.value"
                        v-model:recoveredAmount="bulkQueue.recoveredAmount.value"
                        :totalCandidateSelected="totalSelectedCandidate" />

                    <CandidateUpdateQueueInvestigateNow v-if="bulkQueue.queue.value === 'investigate now'"
                        v-model:optionalNote="bulkQueue.optionalNote.value" />

                </template>
                <template #footer-button>
                    <Button @click="submitBulkQueue()" id="add-bulk-queue"
                        :disabled="bulkQueue.loading.value || bulkQueueUpdateButtonDisabled">
                        <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!bulkQueue.loading.value"></span>
                        <span class="fa fa-spinner fa-spin me-2" aria-hidden="true"
                            v-if="bulkQueue.loading.value"></span>
                        Update
                    </Button>
                </template>
            </BulkActionModalDialog>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue';
import { useAPI } from "@/helpers/services/api"
import { useProfile } from "@/stores/profile"
import { toast } from "@/helpers/toast"
import {
    RELEASED_IR_GRAPIC_URL,
    getApiErrorMessage,
    formatDate,
    buildCopyToClipboardButton,
    QueueCode,
    timeDiffForHumans,
    ucfirst,
    validateUserPermission,
    formatNumberWithCommas,
    MAX_NOTE_LENGTH,
    getCurrencyFormatter,
    getQueueText,
    ucwords,
    getPriorityScoreGraphicUrl
} from "@/helpers/common"
import type { DataTableEvent, FilterConfig, FilterObject, FilterSchema, QueueTabs, QueueTabsText } from "@/helpers/interface/general"
import { FilterMatchMode } from '@primevue/core/api'
import Chip from 'primevue/chip'
import Skeleton from 'primevue/skeleton';
import type { OperatorExclusion, OperatorSubstitution } from "@/helpers/interface/general"
import type { CandidateListNew, Label } from "@/helpers/interface/candidates";
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue"
import Select from 'primevue/select'
import MultiSelect from 'primevue/multiselect'
import Textarea from 'primevue/textarea';
import Message from 'primevue/message'
import StrapCardHeaderActions from "@/components/Shared/StrapCardHeaderActions.vue"
import Checkbox from 'primevue/checkbox';
import SplitButton from 'primevue/splitbutton'
import Button from 'primevue/button'
import type { Auth0User } from "@/helpers/interface/admin-page";
import { ClaimsFields } from "@/helpers/roles";
import { useAdminDetails } from "@/stores/adminDetails";
import { performAssignAction, performIRAction, performLabelUpdate, performNoteAction, performQueueAction, type BulkIRAction } from "@/helpers/bulkActions";
import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import BulkActionModalDialog from '@/components/Shared/BulkActionModalDialog.vue'
import CandidateUpdateQueueInbox from '@/components/Candidates/CandidateUpdateQueueInbox.vue'
import CandidateUpdateQueueQuestionnaire from '@/components/Candidates/CandidateUpdateQueueQuestionnaire.vue'
import CandidateUpdateQueueMonitor from '@/components/Candidates/CandidateUpdateQueueMonitor.vue'
import CandidateUpdateQueueSnooze from '@/components/Candidates/CandidateUpdateQueueSnooze.vue'
import CandidateUpdateQueueUnqualified from '@/components/Candidates/CandidateUpdateQueueUnqualified.vue'
import CandidateUpdateQueueArchive from '@/components/Candidates/CandidateUpdateQueueArchive.vue'
import CandidateUpdateQueueInvestigateNow from '@/components/Candidates/CandidateUpdateQueueInvestigateNow.vue'
import { useCandidateDetailsStore } from '@/stores/candidateDetails'

dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)

const props = defineProps({
    queue: {
        type: String,
        required: true
    }
})
const emits = defineEmits(["updateQueue"])
const api = useAPI()
const { user } = useAuth0()
const storeProfile = useProfile()
const storeAdminDetails = useAdminDetails()
const storeCandidateDetails = useCandidateDetailsStore()
const formatter = getCurrencyFormatter()
const textCandidate = computed(() => totalSelectedCandidate.value > 1 ? "candidates" : "candidate")
const userId = computed(() => {
    return user.value?.sub || null
})

const currentDate = dayjs()
const isSnoozeExpired = (snooze_until: string): boolean => (currentDate.isSameOrAfter(snooze_until, 'day'))

const strapCardHeaderActionsRef = ref<typeof StrapCardHeaderActions | null>(null)
const loading = ref(true)
watch(() => storeProfile.userPermissionsLoadingState, (loadingState: boolean) => {
    loading.value = loadingState
})

const loadingFilterSchema = ref(true)
const filterSchema = ref<FilterSchema[]>([])
const filterConfig = ref<FilterConfig | null>(null)
const first = ref(0)
const rows = ref(25)
const totalRecords = ref(0)
const data = ref<CandidateListNew[]>([])
const selectedBulkAction = ref("")
const userFilters = computed(() => {
    return filters.value.filter(obj => obj.field && obj.type)
})

const dataParams = ref<DataTableEvent | null>(null)
const dataFiltersInitialState = {
    application_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    parcel_num: { value: null, matchMode: FilterMatchMode.CONTAINS },
    applicant: { value: null, matchMode: FilterMatchMode.CONTAINS },
    flags: { value: null, matchMode: FilterMatchMode.IN },
    tax_year: { value: null, matchMode: FilterMatchMode.IN },
    exemptions: { value: null, matchMode: FilterMatchMode.IN },
    submitted_date: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    application_status_recommendation: { value: null, matchMode: FilterMatchMode.IN },
    application_status: { value: null, matchMode: FilterMatchMode.EQUALS },
    assigned_user_id: { value: null, matchMode: FilterMatchMode.EQUALS },
}
const dataFilters = ref({ ...dataFiltersInitialState })
const dataSelection = ref<CandidateListNew[]>([])
const selectAllCurrentPage = ref(false)
const selectAll = ref(false)
const totalSelectedCandidate = computed(() => {
    if (selectAll.value) {
        return totalRecords.value
    }
    if (selectAllCurrentPage.value) {
        return data.value.length
    }
    else {
        return dataSelection.value.length
    }
})

const getDefaultFilters = () => {
    if (props.queue == QueueCode.InvestigationReports) {
        return [{ field: "released_ir", type: "=", value: true }]
    } else if (props.queue !== QueueCode.All) {
        return [{ field: "queue", type: "=", value: props.queue }]
    }
    else if (props.queue === QueueCode.All) {
        return [{ field: "queue", type: "not in", value: "archive" }]
    }
    return []
}
let defaultFilters: FilterObject[] = getDefaultFilters();
const initialFilters = [
    {
        field: "",
        type: "",
        value: ""
    },
]

const filters = ref<FilterObject[]>(initialFilters)
const currentPage = ref(1)
const isFilteringDirty = ref(false)
const hasPermissionToReadCandidates = computed(() => validateUserPermission("read", "candidates"))

const onBulkNoteClick = () => {
    if (checkFiltersDirty())
        return
    bulkNote.showNoteDialog.value = true
}

const onBulkActionClick = (operation: string) => {
    if (checkFiltersDirty())
        return
    selectedBulkAction.value = operation
}

const onBulkIRClick = (action: "release" | "delete") => {
    if (checkFiltersDirty())
        return
    if (action === "release") {
        bulkIR.showReleaseIRDialog.value = true
        bulkIR.showDeleteIRDialog.value = false
    }
    else if (action === "delete") {
        bulkIR.showDeleteIRDialog.value = true
        bulkIR.showReleaseIRDialog.value = false
    }
}

const onFiltersDirtyChanged = (value: boolean) => {
    isFilteringDirty.value = value
}

const checkFiltersDirty = () => {
    if (isFilteringDirty.value) {
        toast.warning("Your filter changes have not been applied.<br>Please review your choices, and press 'Go'.", { "position": "top" })
        return true
    }
    return false
}

const handleUpdateFilters = (updatedFilters: any) => {
    filters.value = updatedFilters
    loadData(null)
}

const handleSubmitFilters = (newFilters: any) => {
    filters.value = newFilters
    loadData(null)
}

const operatorSubstitutions: OperatorSubstitution[] = [
    { type: "date", operator: ">=", text: "Is After" },
    { type: "date", operator: "<", text: "Is Before" },
]

const operatorExclusions: OperatorExclusion[] = [
    { field: "queue", operator: "is null" },
    { field: "prev_queue", operator: "is null" },
]

const onSelectAllChange = (checked: boolean = true) => {
    selectAllCurrentPage.value = checked
    if (selectAll.value) {
        selectAll.value = checked
    }
    selectAllChange()
}

const selectAllChange = () => {
    if (selectAllCurrentPage.value) {
        document.querySelectorAll<HTMLInputElement>(".p-dataview-content .p-checkbox input")?.forEach((checkbox) => {
            // Disable all checkboxes
            checkbox.setAttribute("disabled", "true")
        })
        // Just to check all checkboxes for visual representation of "Select All"
        dataSelection.value = data.value
    }
    else {
        document.querySelectorAll<HTMLInputElement>(".p-dataview-content .p-checkbox input")?.forEach((checkbox) => {
            // Enable checkboxes
            checkbox.removeAttribute("disabled")
            dataSelection.value = []
        })
    }
}

/*** Bulk Queue ***/
const defaultQueueUpdateForm = computed(() => {
    let defaultQueue = props.queue as QueueTabsText
    switch (props.queue) {
        case "released_ir":
            defaultQueue = "unqualified"
            break;
        case "investigate_now":
            defaultQueue = "investigate now"
            break;
        default:
            defaultQueue = bulkQueueLabelOptions.value.includes(ucfirst(props.queue)) ? ucfirst(props.queue) : 'inbox'
            break;
    }
    return defaultQueue
})
const queuesWithoutReasons = ["inbox", "questionnaire", "archive", "investigate now"]
const updateQueueActionItems = [
    { label: 'Inbox', command: () => selectQueueUpdateAction("inbox") },
    { label: 'Questionnaire', command: () => selectQueueUpdateAction("questionnaire") },
    { label: 'Monitor', command: () => selectQueueUpdateAction("monitor") },
    { label: 'Unqualified', command: () => selectQueueUpdateAction("unqualified") },
    { label: 'Snooze', command: () => selectQueueUpdateAction("snooze") },
    { label: 'Archive', command: () => selectQueueUpdateAction("archive") },
    { label: 'Investigate Now', command: () => selectQueueUpdateAction("investigate now") }
]
const mailingTemplateDropdown = computed(() => storeCandidateDetails.getMailTemplateList)
const reasonsDropdown = computed(() => storeCandidateDetails.getReasonsList)
const selectQueueUpdateAction = async (queue: QueueTabsText) => {
    bulkQueue.queue.value = queue?.toLowerCase() as QueueTabsText
    onBulkActionClick(ucwords(queue))
    if (queuesWithoutReasons.includes(bulkQueue.queue.value)) return
    await storeCandidateDetails.fetchReasonsList(loadFailureHandler, bulkQueue.queue.value)
}
const bulkQueueLabelOptions = computed(() => updateQueueActionItems.map((x) => x.label))
const bulkQueue = {
    loading: ref(false),
    showQueueDialog: computed(() => bulkQueueLabelOptions.value.includes(selectedBulkAction.value)),
    optionalNote: ref(""),
    prevQueue: ref<string>(props.queue),
    queue: ref<QueueTabsText | null>(null),
    mailingTemplate: ref<number | null>(null),
    snoozeUntil: ref<string>(""),
    reason: ref<string>(""),
    unqualifiedStartYear: ref<string | null>(null),
    lienOrBackTaxes: ref<string | null>(null),
    backTaxLienAmount: ref<number>(0),
    finalOutcome: ref<string>(""),
    recoveredAmount: ref<number>(0),
    parentURL: "/taxroll"
}
const bulkQueueUpdateButtonDisabled = computed(() => {
    const {
        queue,
        mailingTemplate,
        reason,
        snoozeUntil,
        unqualifiedStartYear,
        lienOrBackTaxes,
        finalOutcome,
        recoveredAmount,
        optionalNote
    } = bulkQueue

    if (!queue.value) return true

    const requireOptionalNote = reason.value.toLowerCase() === 'other'
    const isSingleApplication = totalSelectedCandidate.value === 1

    const hasRequiredFieldsForUnqualified = (includeRecoveredAmount = false) => {
        return unqualifiedStartYear.value && lienOrBackTaxes.value && reason.value &&
            (!requireOptionalNote || optionalNote.value) &&
            (!includeRecoveredAmount || recoveredAmount.value)
    }

    const hasRequiredFieldsForArchive = (includeRecoveredAmount = false) => {
        return unqualifiedStartYear.value && lienOrBackTaxes.value &&
            (!includeRecoveredAmount || recoveredAmount.value)
    }

    const archiveConditions = (): boolean => {
        return finalOutcome.value === 'exemption removed'
            ? !hasRequiredFieldsForArchive(isSingleApplication) || !finalOutcome.value
            : !finalOutcome.value
    }

    const unqualifiedConditions = (): boolean => {
        return !hasRequiredFieldsForUnqualified(isSingleApplication)
    }

    const conditions: Partial<Record<QueueTabs | QueueTabsText, boolean>> = {
        questionnaire: !mailingTemplate.value,
        monitor: (requireOptionalNote) ? !reason.value || !optionalNote.value : !reason.value,
        snooze: (requireOptionalNote) ? !snoozeUntil.value || !reason.value || !optionalNote.value : !snoozeUntil.value || !reason.value,
        unqualified: unqualifiedConditions(),
        archive: archiveConditions()
    }
    return conditions[queue.value] ?? false
})

const fetchMailingTemplateList = async () => {
    await storeCandidateDetails.fetchMailTemplateList(loadFailureHandler)
}
const submitBulkQueue = async () => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performQueueAction(api, bulkQueue, additionalParam)
    if (result === "success") {
        loadData()
        emits("updateQueue", {
            prevQueue: bulkQueue.prevQueue.value,
            queue: bulkQueue.queue.value,
        })
        resetToInitialState()
        closeQueueDialog()
    }
    bulkQueue.loading.value = false
}
const closeQueueDialog = () => {
    selectedBulkAction.value = ""
    bulkQueue.queue.value = null
    bulkQueue.optionalNote.value = ""
    bulkQueue.snoozeUntil.value = ""
    bulkQueue.reason.value = ""
    bulkQueue.mailingTemplate.value = null
    bulkQueue.unqualifiedStartYear.value = null
    bulkQueue.lienOrBackTaxes.value = null
    bulkQueue.backTaxLienAmount.value = 0
    bulkQueue.finalOutcome.value = ""
    bulkQueue.recoveredAmount.value = 0
}

/*** Bulk Assign ***/
const assignActionItems = [
    { label: 'Assign', command: () => onBulkActionClick("Assign") },
    { label: 'Unassign', command: () => onBulkActionClick("Unassign") },
    {
        label: 'Assign to me',
        command: () => onBulkActionClick("Assign To Me"),
        disabled: !(user.value?.[ClaimsFields['app_metadata']]?.is_client)
    },
]
const bulkAssign = {
    sub: user.value?.sub,
    user: ref<Auth0User | null>(null),
    optionalNote: ref(""),
    loading: ref(false),
    showAssignDialog: computed(() => selectedBulkAction.value === "Assign"),
    showAssignToMeDialog: computed(() => selectedBulkAction.value === "Assign To Me"),
    showUnassignDialog: computed(() => selectedBulkAction.value === "Unassign"),
    parentURL: "/taxroll"
}
const userList = computed(() => storeAdminDetails.getUsersList)
const fetchUsersList = async () => {
    await storeAdminDetails.fetchUsersList(loadFailureHandler)
}
const submitBulkAssign = async (action: "assign" | "unassign" | "assigntome") => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performAssignAction(api, action, bulkAssign, additionalParam)
    if (result === "success") {
        loadData()
        resetToInitialState()
        closeAssignDialog()
    }
    bulkAssign.loading.value = false
}
const closeAssignDialog = () => {
    selectedBulkAction.value = ""
    bulkAssign.user.value = null
    bulkAssign.optionalNote.value = ""
}

/*** Bulk Label ***/
const labelActionItems = [
    { label: 'Add', command: () => onBulkActionClick("Add Label") },
    { label: 'Remove', command: () => onBulkActionClick("Remove Label") },
]
const bulkLabel = {
    add: ref<Label[]>([]),
    remove: ref<Label[]>([]),
    optionalNote: ref(""),
    loading: ref(false),
    showAddDialog: computed(() => selectedBulkAction.value === "Add Label"),
    showRemoveDialog: computed(() => selectedBulkAction.value === "Remove Label"),
    parentURL: "/taxroll"
}
const labelList = ref<Label[]>([])
const submitBulkLabel = async (action: "add" | "remove") => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performLabelUpdate(api, action, bulkLabel, additionalParam)
    if (result === "success") {
        loadData()
        resetToInitialState()
        closeLabelDialog()
    }
    bulkLabel.loading.value = false
}
const closeLabelDialog = () => {
    selectedBulkAction.value = ""
    bulkLabel.add.value = []
    bulkLabel.remove.value = []
    bulkLabel.optionalNote.value = ""
}

/*** Bulk Note ***/
const bulkNote = {
    note: ref(""),
    loading: ref(false),
    showNoteDialog: ref(false),
    parentURL: "/taxroll"
}
const submitBulkNote = async () => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performNoteAction(api, bulkNote, additionalParam)
    if (result === "success") {
        loadData()
        resetToInitialState()
        closeNoteDialog()
    }
    bulkNote.loading.value = false
}
const closeNoteDialog = () => {
    selectedBulkAction.value = ""
    bulkNote.note.value = ""
    bulkNote.showNoteDialog.value = false
}
/*** Release IR ***/
const bulkIR = {
    action: ref<BulkIRAction>(null),
    loading: ref(false),
    showReleaseIRDialog: ref(false),
    showDeleteIRDialog: ref(false),
    parentURL: "/taxroll"
}
const submitBulkIR = async (action: BulkIRAction) => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    bulkIR.action.value = action
    const result = await performIRAction(api, bulkIR, additionalParam)
    if (result === "success") {
        loadData()
        emits("updateQueue", {
            prevQueue: props.queue,
            queue: action === "release" ? "released_ir" : "inbox",
        })
        resetToInitialState()
        closeIRDialog()
    }
    bulkIR.loading.value = false
}
const closeIRDialog = () => {
    selectedBulkAction.value = ""
    bulkIR.showReleaseIRDialog.value = false
    bulkIR.showDeleteIRDialog.value = false
}

const initFilters = () => {
    dataFilters.value = dataFiltersInitialState
}
const resetToInitialState = () => {
    dataSelection.value = []
    selectAllCurrentPage.value = false
    selectAll.value = false
    selectedBulkAction.value = ""
    selectAllChange()
}

// For Dynamic ScrollHeight Calculation
const candidateDataView = ref<HTMLElement | null>(null)
const sortOrder = ref<"desc" | "asc">("desc")
const changeSortOrder = () => {
    const queue = props.queue
    const ascQueues = ["questionnaire", "unqualified", "snooze"]
    const defaultSortOrder = ascQueues.includes(queue) ? "asc" : "desc"

    if (sortOrder.value === defaultSortOrder) {
        sortOrder.value = defaultSortOrder === "asc" ? "desc" : "asc"
    }
    else {
        sortOrder.value = defaultSortOrder
    }
    loadData()
}

const getListPayload = (event: any) => {
    return {
        "filter": getFilterList(),
        "page": event?.page,
        "page_size": event?.rows,
        "sort": getSortList(),
        "include_total": false
    }
}

const getFilterList = () => {
    let output: any = [...userFilters.value]
    if (defaultFilters?.length) {
        output = output.concat(defaultFilters)
    }

    if (userId.value && !hasPermissionToReadCandidates.value) {
        let assignedToFilter = output.find((x: FilterObject) => x.field === "assigned_to")
        if (!assignedToFilter) {
            assignedToFilter = { field: "assigned_to", type: "in", value: [userId.value] }
            output.push(assignedToFilter)
        }
    }
    return output
}

const getSortList = () => {
    let fieldName = "last_queue_change";
    let customSortOrder = sortOrder.value
    switch (props.queue) {
        case QueueCode.Inbox:
            fieldName = "priority_score";
            break;
        case QueueCode.Snooze:
            fieldName = "snooze_until";
            customSortOrder = "asc"
            break;
    }
    return [{ "field": fieldName, "dir": customSortOrder }]
}

const loadData = async (event: any = null) => {
    loading.value = true
    dataParams.value = { ...dataParams.value, page: event?.page || 1, rows: event?.rows || 25 }

    const listPayload = getListPayload(dataParams.value)
    const requests = []

    const listRequest = api.post("/taxroll/list", listPayload)
        .then(response => {
            data.value = response.data?.data || []
        })
        .catch(error => {
            data.value = []
            toast.error(getApiErrorMessage(error))
        })
    requests.push(listRequest)

    const countPayload = listPayload.filter
    const countRequest = api.post("/taxroll/count", countPayload)
        .then(response => {
            totalRecords.value = response.data.count
        })
        .catch(error => {
            toast.error(getApiErrorMessage(error))
        })
    requests.push(countRequest)

    await Promise.all(requests)

    let sidebarContent = document.querySelector("#sharedSideNav .sidebar-content") as HTMLDivElement
    if (sidebarContent) {
        sidebarContent.style.marginBottom = totalRecords.value === 0 ? "60px" : "0px"
    }

    loading.value = false

    appendCopyButton()
}

const fetchLabelsDropdownList = async () => {
    try {
        const response = await api.get('/labels/?candidates=true');
        const items = response.data ? response.data : []
        labelList.value = items as Label[]
    }
    catch (error: any) {
        labelList.value = [];
        toast.error(getApiErrorMessage(error))
    }
}

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Report" })
    toast.error(message)
}

const onPage = async (event: DataTableEvent) => {
    event.page += 1
    dataParams.value = event
    currentPage.value = event.page
    rows.value = event.rows as number
    await loadData(event)
    selectAllChange()
    if (!selectAll.value) resetToInitialState()
}


const setFilterConfig = () => {
    const fieldExclusions = props.queue === QueueCode.All ? [] : ["queue"]
    filterConfig.value = {
        activeFilters: filters.value,
        currentFilteredFields: userFilters.value.length,
        showDefaultFilters: (props.queue === "all" ? true : false),
        defaultFilters: defaultFilters,
        filterSchema: filterSchema.value,
        operatorSubstitutions: operatorSubstitutions,
        operatorExclusions: operatorExclusions,
        fieldExclusions: fieldExclusions
    }
}

const fetchFilterSchema = async () => {
    loadingFilterSchema.value = true
    try {
        const response = await api.get('/taxroll/filters')
        filterSchema.value = response.data as FilterSchema[]
        //setFilterConfig()
    }
    catch (error: any) {
        filterSchema.value = [];
        toast.error(getApiErrorMessage(error))
    }
    loadingFilterSchema.value = false
}

// Filter overlay (popup)
const overlayStyle = ref({})
const filterButton = ref<HTMLElement | null>(null)

const setFilterDivPosition = () => {
    if (filterButton.value && candidateDataView.value) {
        const filterButtonRect = filterButton.value.getBoundingClientRect()
        const candidateDataViewRect = candidateDataView.value.getBoundingClientRect()
        overlayStyle.value = {
            position: 'absolute',
            top: `${filterButtonRect.bottom + 15}px`,
            right: `${candidateDataViewRect.right - filterButtonRect.right + 10}px`
        }
    }
}

const appendCopyButton = () => setTimeout(() => buildCopyToClipboardButton(), 1000)

watch(
    [filters, filterSchema],
    () => { setFilterConfig() },
    { deep: true }
)

onMounted(async () => {
    setFilterDivPosition()
    dataParams.value = {
        first: 0,
        rows: 25,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: dataFilters.value
    }
    initFilters()
    fetchFilterSchema()
    await storeProfile.fetchUserPermissions()
    await loadData()
    fetchLabelsDropdownList()
    fetchUsersList()
    fetchMailingTemplateList()
})

</script>

<style>
@import "@/assets/appvet.css";
</style>
