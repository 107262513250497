<template>
    <div class="element-container">
        <label for="mailingTemplate" class="form-label form-label-required">Mailing Template</label>
        <Select id="mailingTemplate" v-model="mailingTemplate" :options="mailingTemplateDropdown" optionLabel="name"
            optionValue="id" placeholder="-- Select --" class="d-flex" />
    </div>

    <div class="element-container">
        <label for="addNote" class="form-label">
            Add Note
        </label>
        <Textarea id="addNote" v-model="optionalNote" :maxlength="MAX_NOTE_LENGTH" class="d-flex" />
    </div>

</template>

<script setup lang="ts">
import { MAX_NOTE_LENGTH } from '@/helpers/common'
import Textarea from 'primevue/textarea'
import Select from 'primevue/select'

const mailingTemplate = defineModel<number | null>("mailingTemplate")
const optionalNote = defineModel<string | null>("optionalNote")
const { mailingTemplateDropdown } = defineProps({
    mailingTemplateDropdown: {
        required: true
    }
})
</script>