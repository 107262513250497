<template>
    <div class="actions-form">
        <div v-if="currentQueue === 'archive'" class="text-center">
            Candidate is archived
        </div>
        <div v-else>
            <div class="actions-description">
                Updating a Candidate Queue allows you to track details and status changes that align with
                your office's workflow.
            </div>
            <form id="candidate-update-queue-form" @submit.prevent="submitUpdateForm()">
                <div class="row pt-2">
                    <div class="col-4">
                        Queue <span class="required">*</span>
                    </div>
                    <div class="col-6">
                        <select class="cbFormTextField" v-model="formUpdate.queue" required id="queueOptions">
                            <option value="" selected>-- Select --</option>
                            <option v-for="q in queueOptions" :value="q.value" :key="q.value">
                                {{ q.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row pt-2"
                    v-if="queuesWithReason.includes(formUpdate.queue) ? !fetchReasonListLoading : false">
                    <div class="col-4">
                        Reason <span class="required"
                            v-if="queuesWithRequiredReason.includes(formUpdate.queue)">*</span>
                    </div>
                    <div class="col-6">
                        <select class="cbFormTextField" v-model="formUpdate.reason"
                            :required="queuesWithRequiredReason.includes(formUpdate.queue)" id="reasonsDropdown">
                            <option value="" selected>-- Select --</option>
                            <option v-for="reason in reasonsDropdown" :value="reason" :key="reason">
                                {{ ucfirst(reason) }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="pt-2" v-if="formUpdate.queue === 'archive'">
                    <div class="actions-description">
                        Only ARCHIVE a Candidate when the property has sold or the exemption has been removed (due to
                        being
                        unqualified or if the exemption has been removed. You cannot reverse this action except through
                        the
                        Admin screens.
                    </div>
                    <div class="row pt-2">
                        <div class="col-4">
                            Final Outcome <span class="required">*</span>
                        </div>
                        <div class="col-6">
                            <select class="cbFormTextField" id="finalOutcome" v-model="formUpdate.final_outcome"
                                required>
                                <option value="" selected>-- Select --</option>
                                <option v-for="outcome in finalOutcomeOptions" :value="outcome.text"
                                    :key="outcome.value">
                                    {{ outcome.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div id="archive-exemptions-removed-options-container"
                        v-if="formUpdate.final_outcome === OUTCOME_EXEMPTION_REMOVED">
                        <div class="row pt-2">
                            <div class="col-4">
                                Unqualified Start Yr <span class="required">*</span>
                            </div>
                            <div class="col-6">
                                <UnqualifiedStartYear id="unqualifiedStartYearFieldArchive"
                                    @fieldUpdate="(value) => updateUnqualifiedStartYear(value)"
                                    :unqualifiedStartYear="formUpdate.unqualified_start_year" />
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4">
                                Lien or Back Taxes
                            </div>
                            <div class="col-6">
                                <select class="cbFormTextField" v-model="formUpdate.lien_or_back_taxes"
                                    id="lienOrBackTaxesArchive">
                                    <option value="" selected>-- Select --</option>
                                    <option value="No" selected>No</option>
                                    <option value="Yes" selected>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4">
                                Estimated Recovered Amount <span class="required">*</span>
                                <abbr class="filter-tooltip" :title="RECOVERED_AMOUNT_TOOLTIP">
                                    <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                                </abbr>
                            </div>
                            <div class="col-6">
                                <input type="text" inputmode="decimal" pattern="[0-9]+(\.[0-9][0-9])?"
                                    class="cbFormTextField" v-model="formUpdate.recovered_amount" maxlength="15"
                                    placeholder="for reporting" :title="MONETARY_INPUT_TOOLTIP"
                                    id="recoveredAmountArchive"
                                    :required="formUpdate.final_outcome === OUTCOME_EXEMPTION_REMOVED">
                            </div>
                        </div>
                        <div class="row pt-2" v-if="formUpdate.lien_or_back_taxes === 'Yes'">
                            <div class="col-4">
                                Back Tax / Lien Amount
                                <abbr class="filter-tooltip" title="For amount recovered via lien">
                                    <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                                </abbr>
                            </div>
                            <div class="col-6">
                                <input type="text" inputmode="decimal" pattern="[0-9]+(\.[0-9][0-9])?"
                                    class="cbFormTextField" v-model="formUpdate.lien_amount" maxlength="15"
                                    id="lienAmountArchive" placeholder="optional, for reporting"
                                    :title="MONETARY_INPUT_TOOLTIP">
                            </div>
                        </div>
                    </div> <!-- end archive-exemptions-removed-options-container-->

                </div>
                <div class="pt-2" v-if="formUpdate.queue === 'snooze'">
                    <div class="actions-description">
                        Move the Candidate to SNOOZE if you want to defer the review. The Candidate will return to the
                        top
                        of
                        your inbox on the “Snooze Until” date.
                    </div>
                    <div class="row pt-2">
                        <div class="col-4">
                            Snooze Until <span class="required">*</span>
                        </div>
                        <div class="col-6">
                            <input type="date" id="snoozeUntil" v-model="formUpdate.snooze_until"
                                class="cbFormTextField" :min="tomorrowDate" required>
                        </div>
                    </div>
                </div>
                <div class="pt-2" v-if="formUpdate.queue === 'questionnaire'">
                    <div class="actions-description">
                        Move the Candidate to QUESTIONNAIRE if you need to gather more information from the taxpayer. If
                        you'd
                        like to send the taxpayer mail, select a Mailing Template.
                    </div>
                    <div class="row pt-2">
                        <div class="col-4">
                            Mailing Template
                        </div>
                        <div class="col-6">
                            <select class="cbFormTextField" v-model="mailingTemplateWrapper"
                                id="mailingTemplateDropdownQuestionnaire">
                                <option value="" selected>-- Select --</option>
                                <option v-for="template in mailingTemplateDropdown" :value="template.id"
                                    :key="template.id">
                                    {{ template.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="pt-2" v-if="formUpdate.queue === 'unqualified'">
                    <div class="actions-description">
                        Move the Candidate to UNQUALIFIED if the exemption needs to be removed, denied for a future
                        year,
                        and/or
                        billed for previous years.
                    </div>
                    <div class="row pt-2">
                        <div class="col-4">
                            Unqualified Start Yr <span class="required">*</span>
                        </div>
                        <div class="col-6">
                            <UnqualifiedStartYear id="unqualifiedStartYearFieldUnqualified"
                                @fieldUpdate="(value) => updateUnqualifiedStartYear(value)"
                                :unqualifiedStartYear="formUpdate.unqualified_start_year" />
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-4">
                            Lien or Back Taxes <span class="required">*</span>
                        </div>
                        <div class="col-6">
                            <select class="cbFormTextField" v-model="formUpdate.lien_or_back_taxes" required
                                id="lienOrBackTaxesUnqualified">
                                <option value="" selected>-- Select --</option>
                                <option value="No" selected>No</option>
                                <option value="Yes" selected>Yes</option>
                            </select>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-4">
                            Mailing Template
                        </div>
                        <div class="col-6">
                            <select class="cbFormTextField" v-model="mailingTemplateWrapper"
                                id="mailingTemplateDropdownUnqualified">
                                <option value="" selected>-- Select --</option>
                                <option v-for="template in mailingTemplateDropdown" :value="template.id"
                                    :key="template.id">
                                    {{ template.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-4">
                            Estimated Recovered Amount <span class="required">*</span>
                            <abbr class="filter-tooltip" :title="RECOVERED_AMOUNT_TOOLTIP">
                                <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                            </abbr>
                        </div>
                        <div class="col-6">
                            <input type="text" inputmode="decimal" pattern="[0-9]+(\.[0-9][0-9])?"
                                class="cbFormTextField" v-model="formUpdate.recovered_amount" maxlength="15"
                                placeholder="for reporting" :title="MONETARY_INPUT_TOOLTIP"
                                id="recoveredAmountUnqualified">
                        </div>
                    </div>
                    <div class="row pt-2" v-if="formUpdate.lien_or_back_taxes === 'Yes'">
                        <div class="col-4">
                            Back Tax / Lien Amount
                            <abbr class="filter-tooltip" title="For amount recovered via lien">
                                <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                            </abbr>
                        </div>
                        <div class="col-6">
                            <input type="text" id="lienAmountUnqualified" inputmode="decimal"
                                pattern="[0-9]+(\.[0-9][0-9])?" class="cbFormTextField" v-model="formUpdate.lien_amount"
                                maxlength="15" placeholder="optional, for reporting" :title="MONETARY_INPUT_TOOLTIP">
                        </div>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="offset-4 col-6">
                        <input type="checkbox" v-model="addNote" id="addNoteCheckbox" class="mx-1">
                        <label for="addNoteCheckbox">
                            Add note
                        </label>
                    </div>
                </div>
                <div class="row pt-2" v-if="addNote">
                    <div class="col-4">
                        Note <span class="required">*</span>
                    </div>
                    <div class="col-6">
                        <textarea v-model="formUpdate.note" id="addNoteField" class="textwrapper"
                            :maxlength="MAX_NOTE_LENGTH" required></textarea>
                    </div>
                </div>

                <div class="row pt-2">
                    <div class="offset-4 col-6 d-flex">
                        <loading-icon v-if="formLoading || fetchReasonListLoading || fetchMailingTemplateLoading" />

                        <button v-if="!confirmationNeeded" class="searchButton" type="submit"
                            :disabled="formLoading || fetchReasonListLoading || fetchMailingTemplateLoading"
                            id="update-btn">Update</button>
                        <button v-else class="searchButton" type="button" @click="archiveSubmitHandler"
                            :disabled="formLoading || fetchReasonListLoading || fetchMailingTemplateLoading"
                            id="update-archive-btn">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <ModalDialog v-if="showArchiveConfirmDialog" title="Archive Candidate" :close="() => { }">
        <p>
            Are you sure you want to archive this candidate?
        </p>
        <p>
            This action can only be reversed by an administrator.
        </p>
        <template #footer>
            <loading-icon v-if="formLoading" />
            <button @click="submitUpdateForm()" :disabled="formLoading"
                class="searchButton removeButton">Archive</button>
            <button @click="showArchiveConfirmDialog = false" class="searchButton clearButton">Cancel</button>
        </template>
    </ModalDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { queueOptions } from "@/helpers/dropdownOptions"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import type { CandidateFormUpdate } from "@/helpers/interface/candidates"
import { useAPI } from "@/helpers/services/api";
import { ucfirst, getApiErrorMessage, dollarStringToFloat, MAX_NOTE_LENGTH, RECOVERED_AMOUNT_TOOLTIP, MONETARY_INPUT_TOOLTIP } from "@/helpers/common";
import { finalOutcomeOptions } from "@/helpers/dropdownOptions";
import { useRoute } from "vue-router";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import UnqualifiedStartYear from "@/components/Shared/UnqualifiedStartYear.vue"
import { toast } from "@/helpers/toast";
import dayjs from "dayjs"
import type { AxiosError } from "axios";

const route = useRoute();
const api = useAPI()
const storeCandidateDetails = useCandidateDetailsStore();

const fetchReasonListLoading = ref(false)
const fetchMailingTemplateLoading = ref(false)
const formLoading = ref(false)
const defaultRA = ref<number | null>(null)
const addNote = ref(false)
const currentQueue = computed({
    get() {
        return storeCandidateDetails.situsInfo.queue
    },
    set(value) {
        storeCandidateDetails.situsInfo.queue = value
    }
})

const OUTCOME_EXEMPTION_REMOVED = finalOutcomeOptions.find(x => x.value === "exemption removed")?.text
const queuesWithReason: string[] = ["monitor", "unqualified", "snooze"]
const queuesWithRequiredReason: string[] = queuesWithReason.filter(reason => reason != 'snooze')
const showArchiveConfirmDialog = ref(false)

const reasonsDropdown = ref<string[]>([])
const mailingTemplateDropdown = computed(() => storeCandidateDetails.getMailTemplateList)
const tomorrowDate: string = dayjs().add(1, "day").toISOString().slice(0, 10)

const formUpdateInitialState = {
    queue: "",
    reason: "",
    final_outcome: "",
    snooze_until: "",
    mailing_template: null,
    unqualified_start_year: "",
    lien_or_back_taxes: "",
    recovered_amount: null,
    lien_amount: "",
}
const formUpdate = ref<CandidateFormUpdate>({ ...formUpdateInitialState })
const info = computed(() => (storeCandidateDetails.situsInfo))
const tru_id = route?.params?.tru_id as string

const mailingTemplateWrapper = computed({
    get() {
        return formUpdate.value.mailing_template?.toString() || ""
    },
    set(value){
        formUpdate.value.mailing_template = value ? parseInt(value, 10) : null
    }
})

// On Load
watch(() => currentQueue.value, (updatedQueue) => {
    formUpdate.value.queue = updatedQueue
    fetchReasonList(updatedQueue)
});

watch(() => addNote.value, () => {
    formUpdate.value.note = ""
})

watch(() => formUpdate.value.reason, () => {
    if (formUpdate.value.reason == 'Other') addNote.value = true
    else addNote.value = false
})

// Request Reason List
watch(() => formUpdate.value.queue, async (updatedQueue) => {
    fetchReasonList(updatedQueue)
    setDefaultRecoveredAmount(updatedQueue)
    await storeCandidateDetails.fetchMailTemplateList(loadFailureHandler)
});

watch(() => formUpdate.value.final_outcome, async () => {
    setDefaultRecoveredAmount(formUpdate.value.queue)
});

const confirmationNeeded = computed(() => {
    return formUpdate.value.queue == "archive"
})

const setDefaultRecoveredAmount = async (queue: string) => {
    if (["unqualified", "archive"].includes(queue)) {
        const av = dollarStringToFloat(info.value.assessed_value)
        const mv = dollarStringToFloat(info.value.market_value)
        const tv = dollarStringToFloat(info.value.taxable_value)
        const ra = Math.abs(Math.max(mv, av) - tv)
        if (!info.value.recovered_amount) {
            if (queue === "unqualified") {
                defaultRA.value = ra || null
                formUpdate.value.recovered_amount = ra
            }
            else {
                if (formUpdate.value.final_outcome === OUTCOME_EXEMPTION_REMOVED) {
                    defaultRA.value = ra || null
                    formUpdate.value.recovered_amount = ra
                }
                else formUpdate.value.recovered_amount = null
            }
        }
        else {
            formUpdate.value.recovered_amount = dollarStringToFloat(info.value.recovered_amount)
        }

    }
    else formUpdate.value.recovered_amount = null
}

let fetchReasonList = async (queueSelected: string) => {
    if (!queuesWithReason.includes(queueSelected)) return

    formUpdate.value.reason = ""
    fetchReasonListLoading.value = true;

    try {
        const { data } = await api.get(`/taxroll/reasons?category=${encodeURIComponent(queueSelected)}`);
        if (data === undefined || !data.data) reasonsDropdown.value = [];
        else reasonsDropdown.value = data.data.reasons;
    }
    catch (error) {
        reasonsDropdown.value = [];
    }
    finally {
        fetchReasonListLoading.value = false;
    }
}

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

const archiveSubmitHandler = () => {
    const form = document.getElementById("candidate-update-queue-form") as HTMLFormElement
    if (form.checkValidity())
        showArchiveConfirmDialog.value = true
    else
        form.reportValidity()
}

const updateUnqualifiedStartYear = (value: string) => formUpdate.value.unqualified_start_year = value

// Submit Form
let submitUpdateForm = async () => {

    if ((formUpdate.value.snooze_until)) formUpdate.value.snooze_until = dayjs(formUpdate.value.snooze_until).toISOString().substring(0, 10)

    const {
        queue,
        final_outcome,
        note,
        reason,
        snooze_until,
        mailing_template,
        unqualified_start_year,
        lien_or_back_taxes,
        recovered_amount,
        lien_amount
    } = formUpdate.value;

    formLoading.value = true

    // Config parameters
    let params = { queue, note } as CandidateFormUpdate
    if (formUpdate.value.queue === 'archive') {
        let data: any = { ...params, final_outcome }
        if (final_outcome === OUTCOME_EXEMPTION_REMOVED) {
            if (lien_or_back_taxes) {
                data.lien_or_back_taxes = lien_or_back_taxes
                if (lien_or_back_taxes === "Yes" && lien_amount)
                    data.lien_amount = lien_amount
            }
            if (recovered_amount)
                data.recovered_amount = recovered_amount
            if (unqualified_start_year)
                data.unqualified_start_year = unqualified_start_year
        }
        params = data
    }
    else if (formUpdate.value.queue === 'monitor') {
        params = { ...params, reason }
    }
    else if (formUpdate.value.queue === 'snooze') {
        // convert snooze_until partial ISO like "2023-10-04" to full ISO string including time
        const snoozeUntilValue = dayjs(snooze_until).toISOString()
        params = { ...params, reason, snooze_until: snoozeUntilValue }
    }
    else if (formUpdate.value.queue === 'questionnaire') {
        params = { ...params, mailing_template }
    }
    else if (formUpdate.value.queue === 'unqualified') {
        let data: any = {
            ...params,
            unqualified_start_year,
            lien_or_back_taxes,
            recovered_amount,
            reason,
            mailing_template
        }
        if (data.lien_or_back_taxes === "Yes" && lien_amount)
            data.lien_amount = lien_amount
        params = data
    }
    
    const payload = { ...params, tru_ids: [tru_id] }

    await api.patch("/taxroll/queue", payload)?.then(async () => {
        showArchiveConfirmDialog.value = false
        currentQueue.value = queue
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchMailList(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchSitusInfo(tru_id, loadFailureHandler)
        toast.success("Thank you for your Candidate update.")

        // Reset
        formUpdate.value = { ...formUpdateInitialState }
        formUpdate.value.queue = currentQueue.value
        if (["unqualified", "archive"].includes(queue)) {
            formUpdate.value.recovered_amount = recovered_amount
            defaultRA.value = recovered_amount || null
        }
        addNote.value = false
    })?.catch((error: AxiosError) => {
        toast.error(getApiErrorMessage(error))
    })?.finally(() => {
        showArchiveConfirmDialog.value = false
        formLoading.value = false;
    })
}

</script>

<style>
@import "@/assets/candidate-details.css";
</style>
