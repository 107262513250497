<template>
    <div>
        <div v-if="queueName === QueueCode.UnreleasedIR && selectedTruIds.length > 0 && hasPermissionToUpdateUnreleasedIR"
            class="tr-tabulator-toolbar">
            <LoadingIcon v-if="releaseSubmitted || deleteSubmitted" class="d-inline-block" />
            <button type="button" id="releaseSubmitted" class="actionButton" :disabled="releaseSubmitted"
                @click="releaseReportHandler">Release
                Selected Reports ({{ selectedTruIds.length }})</button>
            <button type="button" id="deleteSubmitted" class="actionButton danger" :disabled="deleteSubmitted"
                @click="deleteReportHandler">Delete
                Selected Reports ({{ selectedTruIds.length }})</button>
        </div>
        <div id="candidate-list-container"></div>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import type { PropType } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue'
import { useProfile } from "@/stores/profile";
import {
    RELEASED_IR_GRAPIC_URL,
    ucfirst,
    blankIf,
    getQueueName,
    getApiErrorMessage,
    QueueCode,
    validateUserPermission,
    buildCopyToClipboardButton,
    getPriorityScoreGraphicUrl
} from '@/helpers/common';
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import { ref, onMounted, computed, watch, onUnmounted } from 'vue';
import type {
    ColumnDefinition,
    CellComponent
} from "tabulator-tables";
import type { TabulatorFull as Tabulator } from 'tabulator-tables'
import { createAjaxTabulator, destroyTabulator, createAjaxConfig } from "@/helpers/true-tabulator";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import type {
    CandidateListRequestHeader,
    CandidateList,
    CandidateFilter,
    FilterListItem
} from "@/helpers/interface/candidates";
import type { AjaxConfig } from "tabulator-tables";

let tabulator: Tabulator | null;
let ajaxConfig: AjaxConfig;

interface Detail {
    text: string
    value: keyof CandidateList
}

const baseURL = import.meta.env.VITE_DEV_API_URL;
const props = defineProps({
    requestHeaders: {
        type: Object as PropType<CandidateListRequestHeader>,
        required: true
    }
});
const hasPermissionToUpdateUnreleasedIR = computed(() => validateUserPermission("create", "released_investigation_reports"))
const hasPermissionToReadCandidates = computed(() => validateUserPermission("read", "candidates"))
const filter = props.requestHeaders.filter as CandidateFilter[]
const currentQueue: any = ref(filter);
const selectedTruIds = ref<string[]>([])
const releaseSubmitted = ref(false)
const deleteSubmitted = ref(false)
const tabulatorCheckboxState = ref(false)

const queueName = computed(() => {
    const item = props.requestHeaders?.filter?.find(x => x.field === "queue" && x.type === "=")
    return item?.value
})

const api = useAPI()
const storeProfile = useProfile()

const candidatesListColumns: ColumnDefinition[] = [
    {
        title: "",
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        headerSort: false,
    },
    {
        title: "Parcel Number",
        field: "parcel_num",
        headerSort: false,
        visible: true,
        width: "10%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const parcel_num = data.parcel_num
            const tru_id = data.tru_id

            const parcelNumberEl = document.createElement("a")
            parcelNumberEl.target = "_blank"
            parcelNumberEl.classList.add("candidate-list-link", "me-1")
            parcelNumberEl.href = `/candidate-details/${tru_id}`
            parcelNumberEl.innerText = parcel_num

            const copyElement = document.createElement("span")
            copyElement.classList.add("clipboard-copy-target")
            copyElement.setAttribute("id", `parcel-number-${tru_id}`)
            copyElement.setAttribute("data-copy-text", parcel_num)

            const parent = document.createElement("div")
            parent.appendChild(parcelNumberEl)
            parent.appendChild(copyElement)

            return parent
        }
    },
    {
        title: "Details", field: "owner", hozAlign: 'left',
        width: "35%",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList
            const tru_id = data.tru_id

            const parent = document.createElement("div")
            parent.className = "row list-row"
            parent.id = "details"

            function appendDetailRow(container: HTMLElement, label: string, value: string): void {
                const rowDiv = document.createElement("div")
                rowDiv.className = "col-3"
                rowDiv.textContent = `${label}: `
                container.appendChild(rowDiv)

                const valueDiv = document.createElement("div")
                valueDiv.className = "col-9 text-truncate white-space-normal"
                valueDiv.innerHTML = value

                // Append copy button
                if (["Situs", "Mail"].includes(label) && value) {
                    valueDiv.className = "col-9 text-truncate d-flex"
                    const copyElement = document.createElement("span")
                    copyElement.classList.add("clipboard-copy-target")
                    copyElement.setAttribute("id", `${label}-${tru_id}`)
                    copyElement.setAttribute("data-copy-text", value)
                    valueDiv.append(copyElement)
                }
                container.appendChild(valueDiv)
            }

            ([
                { text: "Owner", value: "owner" },
                { text: "Situs", value: "situs" },
                { text: "Mail", value: "mail" },
                { text: "Exemption(s)", value: "exemptions" },
                { text: "Label(s)", value: "labels" }
            ] as Detail[]).forEach((detail) => {
                appendDetailRow(parent, detail.text, (data[detail.value]) as string);
            });

            const deedDate = data.deed_date ? dayjs(data.deed_date).format("MM/DD/YYYY") : "";
            appendDetailRow(parent, "Deed/Qual", deedDate);

            return parent;
        }
    },
    {
        title: "Flags", field: "flags", hozAlign: 'left',
        width: "20%",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const flags = data.flags
            let returnFlags = ""
            if (flags == null || flags.length == 0) return returnFlags
            for (let index = 0; index < flags.length; index++) {
                returnFlags += `${flags[index]} <br>`;
            }
            return returnFlags
        }
    },
    {
        title: "Status", field: "queue", hozAlign: 'left',
        width: "20%",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const updated_on = data.updated_on
            const identified_on = data.identified_on
            const queue = getQueueName(data.queue)
            const prev_queue = getQueueName(data.prev_queue)
            const assigned_to = data.assigned_to

            let _updated_on = blankIf(updated_on) == "" ? "" : dayjs(updated_on).format("MM/DD/YYYY")
            let _identified_on = blankIf(identified_on) == "" ? "" : dayjs(identified_on).format("MM/DD/YYYY")
            return `
                <div class="row list-row">
                    <div class="col-4">Queue:</div> <div class="col-8">${blankIf(ucfirst(queue))}</div>
                    <div class="col-4">Prev Queue:</div> <div class="col-8">${blankIf(ucfirst(prev_queue))}</div>
                    <div class="col-4">Updated On:</div> <div class="col-8">${_updated_on}</div>
                    <div class="col-4">Identified On:</div> <div class="col-8">${_identified_on}</div>
                    <div class="col-4">Assigned To:</div> <div class="col-8">${blankIf(assigned_to)}</div>
                </div>
            `;
        }
    },
    {
        title: "Priority", field: "priority_score", hozAlign: 'left',
        headerSort: false,
        width: "15%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const priorityScore = data.priority_score
            const releasedIR = data.released_ir

            const imgContainer = document.createElement("div")
            const img = document.createElement("img")

            imgContainer.classList.add("candidate-list-img-container")
            img.alt = "priority_score"

            if (releasedIR) {
                img.classList.add("candidate-list-img-container-sm")
                img.src = RELEASED_IR_GRAPIC_URL
            }
            else img.src = getPriorityScoreGraphicUrl(priorityScore)

            imgContainer.append(img)
            return imgContainer
        }
    },
]

async function candidates(filters: FilterListItem[]) {
    const currentCheckboxColumnVisible = tabulatorCheckboxState.value
    const showCheckboxColumn = queueName.value === QueueCode.UnreleasedIR
    const columns = showCheckboxColumn ? candidatesListColumns : candidatesListColumns.slice(1)
    let previousSelectedData: any[]

    tabulatorCheckboxState.value = showCheckboxColumn

    if (!hasPermissionToReadCandidates.value && filters.length) {
        let assignedToFilter = filters.find(x => x.field === "assigned_to")
        if (!assignedToFilter) {
            assignedToFilter = { field: "assigned_to", type: "=", value: "me" }
            filters.push(assignedToFilter)
            currentQueue.value = filters
        }
    }

    if (tabulator) {
        // if tabulator has already been instantiated...
        selectedTruIds.value = []

        if (showCheckboxColumn !== currentCheckboxColumnVisible) {
            // we need to turn on/off row selection - destroy and start over
            tabulator.destroy()
        } else {
            // if we don't need to start over, just clear alerts, reset filter, exit
            tabulator.clearAlert()
            tabulator.setFilter(filters)
            return;
        }
    }

    tabulator = await createAjaxTabulator("#candidate-list-container", {
        ajaxConfig: ajaxConfig,
        columns: columns,
        height: "700px",
        placeholder: "There are no candidates to display",
        pagination: true,
    })

    tabulator.on("tableBuilt", () => {
        if (!tabulator)
            return

        if (tabulatorCheckboxState.value) {
            // When row selection is enabled, Tabulator will not retain selected rows during progressive load.
            // Store them before more data is retreived, and restore after processing

            tabulator.on("dataLoading", () => {
                if (showCheckboxColumn) {
                    previousSelectedData = tabulator!.getSelectedData()
                }
            });
            tabulator.on("dataProcessed", () => {
                if (showCheckboxColumn && previousSelectedData && previousSelectedData.length) {
                    const truIds = previousSelectedData.map(c => c.tru_id)
                    const previousSelectedRows = tabulator!.getRows().filter(row => truIds.includes(row.getData().tru_id))
                    if (previousSelectedRows.length) {
                        tabulator!.selectRow(previousSelectedRows)
                    }
                }
            })
            tabulator.on("rowSelectionChanged", () => {
                const items = tabulator?.getSelectedData().map(x => x.tru_id) as string[]
                selectedTruIds.value = items;
            });
        }
        tabulator.setFilter(filters)
        tabulator.setData(`${baseURL}/taxroll/`)
        setTimeout(() => buildCopyToClipboardButton(), 1000)
    })
    tabulator.on("pageLoaded", () => setTimeout(() => buildCopyToClipboardButton(), 1000))
    tabulator.on("scrollVertical", () => buildCopyToClipboardButton())
}

const releaseReportHandler = async () => {
    const tru_ids = selectedTruIds.value
    releaseSubmitted.value = true

    if (confirm(`Are you sure you want to release the ${tru_ids.length} selected investigation report(s)?\n\nThis action cannot be undone.`)) {
        try {
            await api.patch("/taxroll/release_investigation_reports", { "tru_ids": tru_ids })
            toast.success("Investigation reports released")
            selectedTruIds.value = []

            //refresh the candidate list
            candidates(currentQueue.value)

        } catch (error: any) {
            toast.error(getApiErrorMessage(error))
        }
    }

    releaseSubmitted.value = false
}

const deleteReportHandler = async () => {
    const tru_ids = selectedTruIds.value
    deleteSubmitted.value = true

    if (confirm(`Are you sure you want to delete the ${tru_ids.length} selected investigation report(s)?\n\nThis action cannot be undone.`)) {
        try {
            await api.patch("/taxroll/investigation_reports/delete", { "tru_ids": tru_ids })
            toast.success("Investigation reports deleted")
            selectedTruIds.value = []

            //refresh the candidate list
            candidates(currentQueue.value)

        } catch (error: any) {
            toast.error(getApiErrorMessage(error))
        }
    }

    deleteSubmitted.value = false
}

onMounted(async () => {
    const { getAccessTokenSilently } = useAuth0()
    const accessToken = await getAccessTokenSilently()
    ajaxConfig = createAjaxConfig(accessToken)
    await storeProfile.fetchUserPermissions()
    await candidates(currentQueue.value)
})

onUnmounted(() => {
    destroyTabulator(tabulator)
})

watch(() => props.requestHeaders.filter, (first) => {
    currentQueue.value = first
    candidates(currentQueue.value)
});
</script>

<style>
@import "@/assets/candidates-list.css";
@import "tabulator-tables";
</style>
