<template>
    <ModalDialog v-if="props.visible" :title="dialogTitle" :close="props.closeDialog">
        <Message severity="warn" :closable="false" class="my-2" v-if="props.selectAllCurrentPage">
            <span v-if="!props.selectAll">
                Changes will be applied to all applications on this page.
            </span>
            <span v-else>
                Changes will be applied to all applications in this view.
            </span>
        </Message>
        <slot name="form-fields"></slot>
        <template #footer>
            <slot name="footer-button"></slot>
            <Button @click="props.closeDialog()" severity="secondary">
                <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
            </Button>
        </template>
    </ModalDialog>
</template>

<script setup lang="ts">
import Message from 'primevue/message'
import Button from 'primevue/button'
import ModalDialog from './ModalDialog.vue';
const props = defineProps({
    visible: {
        type: Boolean,
        required: true
    },
    closeDialog: {
        type: null,
        required: true
    },
    selectAllCurrentPage: {
        type: Boolean,
        required: true
    },
    selectAll: {
        type: Boolean,
        required: true
    },
    dialogTitle: {
        type: String,
        required: true
    }
})
</script>