<template>
    <div class="position-relative">
        <label class="config-header">
            Update: Assign Candidates
        </label>
        <div class="actions-description">
            This update screen allows you to search for and assign Candidates to specific TrueRoll users on your team.
            For
            jurisdictions with "Update: Assign Candidates" enabled, users with the Client Specialist role will only have
            Candidates in
            their INBOX that they have been specifically assigned. To set this up in your jurisdiction, email
            support@trueroll.io.
        </div>
        <div>
            <div>
                <!-- Filtering -->
                <form @submit.prevent="submitFilter" @keydown.enter.prevent="submitFilter">
                    <table class="candidates-list-filtering overflow-auto">
                        <tr class="cbFormTableRow p-2">
                            <td class="p-2">
                                <label for="filter-pin">PIN</label>
                                <br>
                                <input id="filter-pin" type="text" maxlength="255" size="25" class="cbFormTextField"
                                    placeholder="no spaces/dashes, begins with" v-model="filterFields.parcel_num">
                            </td>
                            <td class="p-2">
                                <label for="filter-Queue">Queue</label>
                                <br>
                                <select id="filter-Queue" class="cbFormTextField cbFormTextField-xl"
                                    v-model="filterFields.queue" required>
                                    <option value="" selected>-- Any Queue --</option>
                                    <option v-for="queue in filterQueues" :value="queue" :key="queue">
                                        {{ ucfirst(queue) }}
                                    </option>
                                </select>
                            </td>
                            <td class="p-2">
                                <label for="filter-exemptions">Exemption</label>
                                <br>
                                <input id="filter-exemptions" type="text" maxlength="255" class="cbFormTextField"
                                    size="25" placeholder="Exemption (**contains**)" v-model="filterFields.exemptions">
                            </td>
                            <td class="p-2">
                                <label for="filter-flags">Flags</label>
                                <br>
                                <select id="filter-flags" class="cbFormTextField" v-model="filterFields.flags">
                                    <option value="" selected>Any</option>
                                    <option v-for="flags in flagsOptions" :value="flags.value" :key="flags.value">
                                        {{ flags.text }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr class="cbFormTableRow p-2">
                            <td class="p-2">
                                <label for="filter-label">Label</label>
                                <br>
                                <select class="cbFormTextField cbFormTextField-xl" id="filter-label"
                                    v-model="filterFields.label" required>
                                    <option value="" selected>-- Any Labels --</option>
                                    <option v-for="label in labelsList" :value="label.label_name"
                                        :key="label.label_name">
                                        {{ ucfirst(label.label_name) }}
                                    </option>
                                </select>
                            </td>
                            <td class="p-2">
                                <label for="filter-Confidence">Confidence</label>
                                <br>
                                <select class="cbFormTextField cbFormTextField-xl" id="filter-Confidence"
                                    v-model="filterFields.priority_score" required>
                                    <option value="" selected>-- Any Confidence --</option>
                                    <option value="1">1 bar or greater</option>
                                    <option value="2">2 bars or greater</option>
                                    <option value="3">3 bars or greater</option>
                                    <option value="4">4 bars or greater</option>
                                    <option value="5">5 bars or greater</option>
                                </select>
                            </td>
                            <td class="p-2">
                                Investigation Report
                                <br>
                                <input type="checkbox" id="any" v-model="filterFields.has_ir"
                                    class="advanced-search-radio" style="margin-left: 0px !important" />
                            </td>
                            <td class="p-2">
                                IR ON or Before Year
                                <br>
                                <select class="cbFormTextField cbFormTextField-xl" id="filter-ir-year"
                                    v-model="filterFields.ir_first_unqualified_year" required>
                                    <option value="" selected>-- Any IR ON or Before Year --</option>
                                    <option v-for="year in dropdownYear" :value="year" :key="year">
                                        {{ ucfirst(year) }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr class="cbFormTableRow p-2">
                            <td class="p-2">
                                Currently Assigned User
                                <br>
                                <select class="cbFormTextField cbFormTextField-xl" v-model="filterFields.assigned_to"
                                    required>
                                    <option value="" selected>-- Any Assigned User --</option>
                                    <option v-for="users in userList" :value="users.user_id" :key="users.user_id">
                                        {{ users.name }}
                                    </option>
                                </select>
                            </td>
                            <td class="p-2">
                                <label for="filter-unassigned">Not Assigned</label>
                                <br>
                                <input type="checkbox" id="filter-unassigned" v-model="filterFields.unassigned"
                                    class="advanced-search-radio" style="margin-left: 0px !important" />
                            </td>
                        </tr>
                    </table>
                </form>

                <div class="mt-3 mb-3">
                    <div class="row p-1">
                        <div class="col-md-6">
                            <button type="submit" @click="submitFilter" :disabled="filterDataRequestLoading"
                                class="searchButton">Search</button>
                            <button type="button" @click="showClearSearchConfirmation = true"
                                :disabled="filterDataRequestLoading" class="clearButton">Clear Search</button>

                        </div>
                        <div class="col-md-6 text-end d-block">
                            <div class="d-inline-flex">
                                <button type="button" @click="() => showBulkUpdateDialog = true" class="warningButton"
                                    :disabled="selectedBulkAssignCandidate.length === 0"
                                    v-if="hasPermissionToUpdateCandidates">
                                    <span class="fa fa-edit" title="Bulk Edit" aria-hidden="true"></span>
                                    Bulk Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="candidate-list-container"></div>
            </div>
        </div>

        <teleport to="body">
            <ModalDialog v-if="showBulkUpdateDialog" :title="`Editing ${selectedBulkAssignCandidate.length} Record(s)`"
                :close="() => showBulkUpdateDialog = false">
                <form @submit.prevent="updateCandidate(bulkUpdate, 'assign', 'assigned')" id="bulkUpdateForm">
                    <div class="row">
                        <div class="col-4">
                            <label for="assigned-to">Assigned To</label>
                        </div>
                        <div class="col-8">
                            <select class="cbFormTextField cbFormTextField-xl" v-model="bulkUpdate.user_id"
                                id="assigned-to" required>
                                <option value="" selected>-- Select --</option>
                                <option v-for="users in userList" :value="users.user_id" :key="users.user_id">
                                    {{ users.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>

                <template #footer>
                    <loading-icon v-if="formAssignCandidateLoading || formUnassignCandidateLoading" />
                    <button style="border: 1px solid #ccc; background-color: var(--main-warning-color);"
                        @click="validateAndSubmitForm('bulkUpdateForm')" :disabled="formAssignCandidateLoading"
                        form="bulkUpdateForm" class="btn btn-success">Assign</button>
                    <button v-if="bulkUpdate.tru_ids" @click="updateCandidate(bulkUpdate, 'unassign', 'unassigned')"
                        :disabled="formUnassignCandidateLoading" form="inlineUpdateForm" class="btn btn-danger">
                        Unassign
                    </button>
                    <button style="border: 1px solid #ccc" @click="showBulkUpdateDialog = false"
                        class="btn btn-default">Close</button>
                </template>
            </ModalDialog>

            <ModalDialog v-if="inlineUpdateDialog"
                :title="`Editing Parcel Number ${inlineSelectedCandidate.parcel_num}`"
                :close="() => inlineUpdateDialog = false">
                <form @submit.prevent="updateCandidate(inlineUpdateObject, 'assign', 'assigned')" id="inlineUpdateForm">

                    <div class="row">
                        <div class="col-4">
                            <label for="assigned-to-inline">Assigned To</label>
                        </div>
                        <div class="col-8">
                            <select class="cbFormTextField cbFormTextField-xl" v-model="inlineUpdateObject.user_id"
                                id="assigned-to-inline" required>
                                <option value="" selected>-- Select --</option>
                                <option v-for="users in userList" :value="users.user_id" :key="users.user_id">
                                    {{ users.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>
                <template #footer>
                    <loading-icon v-if="formAssignCandidateLoading || formUnassignCandidateLoading" />
                    <button style="border: 1px solid #ccc; background-color: var(--main-warning-color);"
                        @click="validateAndSubmitForm('inlineUpdateForm')" :disabled="formAssignCandidateLoading"
                        form="inlineUpdateForm" class="btn btn-success">Assign</button>
                    <button v-if="inlineSelectedCandidate.assigned_to"
                        @click="updateCandidate(inlineUpdateObject, 'unassign', 'unassigned')"
                        :disabled="formUnassignCandidateLoading" form="inlineUpdateForm" class="btn btn-danger">
                        Unassign
                    </button>
                    <button style="border: 1px solid #ccc" @click="() => inlineUpdateDialog = false"
                        class="btn btn-default">Close</button>
                </template>

            </ModalDialog>

            <ModalDialog v-if="showClearSearchConfirmation" title="Confirm Clear Search"
                :close="() => showClearSearchConfirmation = false">
                Are you sure you want to clear the search?
                <br>
                <br>
                This action cannot be undone, and all current search filters
                and input will be removed.

                <template #footer>
                    <button @click="clearSearchField" class="btn confirmClearSearch">Confirm</button>
                    <button style="border: 1px solid #ccc" @click="showClearSearchConfirmation = false"
                        class="btn btn-default">Close</button>
                </template>
            </ModalDialog>
        </teleport>
    </div>
</template>

<script setup lang="ts">
import type { AxiosError } from "axios"
import { toast } from "@/helpers/toast"
import { useAuth0 } from '@auth0/auth0-vue'
import { useAPI } from "@/helpers/services/api";
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import ModalDialog from '@/components/Shared/ModalDialog.vue';
import { ucfirst, QueueCode, getApiErrorMessage, prepareFilterFieldObject, validateUserPermission, getPriorityScoreGraphicUrl, RELEASED_IR_GRAPIC_URL } from '@/helpers/common';
import type { CandidateFilter, CandidateList, CandidateListFilterForm, CandidateListRequestHeader } from "@/helpers/interface/candidates";
import { createAjaxTabulator, destroyTabulator, createAjaxConfig } from "@/helpers/true-tabulator";
import type { AjaxConfig, ColumnDefinition, CellComponent, TabulatorFull as Tabulator } from "tabulator-tables";
import { flagsOptions } from "@/helpers/dropdownOptions"
import { useAdminDetails } from "@/stores/adminDetails";
import dayjs from "dayjs";

const api = useAPI()
const baseURL = import.meta.env.VITE_DEV_API_URL;
const { getAccessTokenSilently } = useAuth0()
const storeAdminDetails = useAdminDetails();
const hasPermissionToUpdateCandidates = computed(() => validateUserPermission("update", "candidates"))

// Filters
const filterQueues = [QueueCode.Inbox, QueueCode.Questionnaire, QueueCode.Unqualified, QueueCode.Snooze, QueueCode.Archive, QueueCode.Monitor]
const labelsList = computed(() => storeAdminDetails.getAllLabels)
const filterFieldsInitialState = {
    parcel_num: "",
    queue: "",
    exemptions: "",
    flags: "",
    label: "",
    priority_score: "",
    has_ir: false,
    ir_first_unqualified_year: "",
    assigned_to: "",
    unassigned: false
}
const filterFields = ref<Partial<CandidateListFilterForm>>({ ...filterFieldsInitialState })
const defaultQueueFilter = {
    field: 'queue',
    type: 'in',
    value: filterQueues
}
const sendRequestHeaders = ref<CandidateListRequestHeader>({
    // Default values
    filter: [defaultQueueFilter],
    page: 1
})
const currentQueue: any = ref(sendRequestHeaders.value.filter);
const dropdownYear = ref<string[]>([])
const currentYear = parseInt(dayjs().format("YYYY"))
for (let index = 2009; index <= currentYear + 1; index++) {
    if (index <= currentYear + 1) dropdownYear.value.push(index.toString())
}
const showClearSearchConfirmation = ref<boolean>(false)

// Bulk Edit
const selectedBulkAssignCandidate = ref<string[]>([])
const showBulkUpdateDialog = ref(false)
const formAssignCandidateLoading = ref(false)
const formUnassignCandidateLoading = ref(false)
const updateObjectInitialState = {
    user_id: "",
    tru_ids: [] as string[]
}
const bulkUpdate = ref({ ...updateObjectInitialState })
const userList = computed(() => storeAdminDetails.getUsersList)

// Inline edit
const inlineUpdateDialog = ref(false)
const inlineUpdateObject = ref({ ...updateObjectInitialState })
const inlineSelectedCandidate = ref({
    tru_id: "",
    parcel_num: "",
    assigned_to: "",
    assigned_to_user_id: "",
})

// Loading variables
const getTaxRollLoading = ref(false)
const getAllLabelsListLoading = ref(false)
const getAllFlagsLoading = ref(false)
const getUsersListLoading = ref(false)
const filterDataRequestLoading = computed(() => getTaxRollLoading.value || getAllLabelsListLoading.value || getAllFlagsLoading.value || getUsersListLoading.value)

// Tabulator stuff
let tabulator: Tabulator | null;
let ajaxConfig: AjaxConfig;
const tabulatorCurrentPage = ref(1)
const candidatesListColumns: ColumnDefinition[] = [

    {
        title: "",
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        headerSort: false,
        visible: hasPermissionToUpdateCandidates.value,
    },
    {
        title: "PIN",
        field: "parcel_num",
        headerSort: false,
        width: "25%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const parcel_num = data.parcel_num
            const exemptions = data.exemptions
            const tru_id = data.tru_id
            return `
                <div style=''>
                <a target="_blank" class="candidate-list-link" href="/candidate-details/${tru_id}">${parcel_num}</a>
                <br>
                Exemption(s): <br> ${exemptions?.split("|")?.join("<br>") || ""}
                </div>
            `;
        }
    },
    {
        title: "Queue", field: "queue", hozAlign: 'left',
        width: "15%",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const queue = data.queue
            return ucfirst(queue);
        }
    },
    {
        title: "Flags", field: "flags", hozAlign: 'left',
        width: "20%",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const flags = data.flags
            let returnFlags = ""
            if (flags == null || flags.length == 0) return returnFlags
            for (let index = 0; index < flags.length; index++) {
                returnFlags += `${flags[index]} <br>`;
            }
            return returnFlags
        }
    },
    {
        title: "Priority", field: "priority_score", hozAlign: 'left',
        headerSort: false,
        width: "15%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const priorityScore = data.priority_score
            const queue = data.queue
            const releasedIR = data.released_ir

            const imgContainer = document.createElement("div")
            const img = document.createElement("img")

            imgContainer.classList.add("candidate-list-img-container")
            img.alt = "priority_score"

            if (queue === "inbox" && releasedIR) {
                img.classList.add("candidate-list-img-container-sm")
                img.src = RELEASED_IR_GRAPIC_URL
            }
            else img.src = getPriorityScoreGraphicUrl(priorityScore)

            imgContainer.append(img)
            return imgContainer
        }
    },
    {
        title: "Assigned To", field: "assigned_to", hozAlign: 'left',
        width: "15%",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateList;
            const assigned_to = data.assigned_to
            return assigned_to;
        }
    },
]

const submitFilter = async () => {
    const formatFilterFields = [
        { key: "pin", operator: "like", value: filterFields.value.parcel_num ? filterFields.value.parcel_num + "%" : "" },
        { key: "owner", operator: "like", value: filterFields.value.owner },
        { key: "flags", operator: "like", value: filterFields.value.flags },
        { key: "exemptions", operator: "like", value: filterFields.value.exemptions },
        { key: "label", operator: "=", value: filterFields.value.label },
        { key: "assigned_to", operator: "=", value: filterFields.value.assigned_to },
        { key: "priority_score", operator: ">=", value: filterFields.value.priority_score },
    ];

    const filter = formatFilterFields.filter(field => field.value !== "" && field.value !== null && field.value !== undefined).map(field => prepareFilterFieldObject(field.key, field.operator, field.value));

    if (filterFields.value.unassigned === true) {
        filter.push(prepareFilterFieldObject("unassigned", "=", "true"));
    }

    if (filterFields.value.has_ir === true && filterFields.value.ir_first_unqualified_year === "") {
        // only include if has_ir === true and no value in ir_first_unqualified_year field
        filter.push(prepareFilterFieldObject("has_ir", "=", "true"));
    }

    if (filterFields.value.ir_first_unqualified_year !== "") {
        filter.push(prepareFilterFieldObject("ir_first_unqualified_year", "<=", filterFields.value.ir_first_unqualified_year));
    }

    if (filterFields.value.queue === "") {
        filter.push(prepareFilterFieldObject("queue", "in", filterQueues));
    }
    else {
        filter.push(prepareFilterFieldObject("queue", "=", filterFields.value.queue));
    }

    tabulatorCurrentPage.value = 1
    sendRequestHeaders.value.filter = filter
    currentQueue.value = filter
    await candidates(currentQueue.value)
}

const clearSearchField = () => {
    showClearSearchConfirmation.value = false
    filterFields.value = { ...filterFieldsInitialState }
    tabulatorCurrentPage.value = 1
    submitFilter()
}

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error, { "featureName": "Customer List" })
    toast.error(message)
}

const validateAndSubmitForm = (id: string) => {
    const form = document.getElementById(id) as HTMLFormElement
    if (!form) return
    form.checkValidity()
};

const updateCandidate = async (data: {}, endpoint: string, successMessage: string) => {
    formAssignCandidateLoading.value = endpoint === 'assign' ? true : false
    formUnassignCandidateLoading.value = endpoint === 'unassign' ? true : false
    try {
        await api.patch(`/taxroll/${endpoint}`, data)
        toast.success(`Successfully ${successMessage} candidates`)
        await candidates(currentQueue.value)
    } catch (error: unknown) {
        const err = error as AxiosError
        toast.error(getApiErrorMessage(err))
    }

    formAssignCandidateLoading.value = false
    formUnassignCandidateLoading.value = false
    data = { ...updateObjectInitialState }
    inlineSelectedCandidate.value = {
        tru_id: "",
        parcel_num: "",
        assigned_to: "",
        assigned_to_user_id: "",
    }
    bulkUpdate.value.user_id = ""
    showBulkUpdateDialog.value = false
    inlineUpdateDialog.value = false
}

async function candidates(filter: CandidateFilter[]) {
    getTaxRollLoading.value = true
    const columns = candidatesListColumns
    const accessToken = await getAccessTokenSilently()
    ajaxConfig = createAjaxConfig(accessToken)
    if (tabulator) {
        tabulator.clearAlert()
        tabulator.setFilter(filter)
        getTaxRollLoading.value = false
        return;
    }

    tabulator = await createAjaxTabulator("#candidate-list-container", {
        ajaxConfig: ajaxConfig,
        columns: columns,
        height: "700px",
        placeholder: "There are no candidates to display",
        pagination: true,
    })


    tabulator.on("tableBuilt", () => {
        if (!tabulator) return
        tabulator.on("rowSelectionChanged", () => {
            const items = tabulator?.getSelectedData().map(x => x.tru_id) as string[]
            selectedBulkAssignCandidate.value = items;
            bulkUpdate.value.tru_ids = selectedBulkAssignCandidate.value
        })
        tabulator.on("dataLoaded", () => {
            tabulatorCurrentPage.value = tabulator?.getPage() || 1
        })
        tabulator.setFilter(filter)
        tabulator.setData(`${baseURL}/taxroll/`)
        getTaxRollLoading.value = false
    })
}

const fetchLabelList = async () => {
    getAllLabelsListLoading.value = true
    await storeAdminDetails.fetchLabelsList(loadFailureHandler)
    getAllLabelsListLoading.value = false
}

const fetchUsersList = async () => {
    getUsersListLoading.value = true
    await storeAdminDetails.fetchUsersList(loadFailureHandler)
    getUsersListLoading.value = false
}

onMounted(async () => {
    candidatesListColumns.push(
        {
            title: "&nbsp;",
            field: "name",
            width: "5vh",
            headerSort: false,
            visible: hasPermissionToUpdateCandidates.value,
            formatter: function (cell: CellComponent) {
                const data = cell.getData() as CandidateList
                const assigned_to_user_id = userList.value.filter(user => user.user_id == data.assigned_to_id).map(user => user.user_id)?.[0] || ""
                const edit = {
                    tru_id: data.tru_id,
                    assigned_to: data.assigned_to,
                    parcel_num: data.parcel_num,
                    assigned_to_user_id: assigned_to_user_id

                }

                const actionParent = document.createElement("div")
                const editButton = document.createElement("a")
                editButton.innerHTML = "<span class='fa fa-edit' aria-hidden='true'></span> "
                editButton.title = "Edit"
                editButton.classList.add("edit-btn")
                editButton.classList.add("mr-1")
                editButton.href = "javascript:;"
                editButton.addEventListener("click", () => {
                    inlineUpdateObject.value = {
                        tru_ids: [data.tru_id],
                        user_id: assigned_to_user_id,
                    }
                    inlineSelectedCandidate.value = edit
                    inlineUpdateDialog.value = true
                })
                actionParent.append(editButton)
                return actionParent
            },
        })


    await candidates(currentQueue.value)
    await fetchUsersList()
    await fetchLabelList()
})

onUnmounted(() => destroyTabulator(tabulator))

watch(() => filterFields.value.unassigned, (unassigned) => {
    if (unassigned) filterFields.value.assigned_to = ""
})

watch(() => filterFields.value.assigned_to, (assigned_to) => {
    if (assigned_to !== "") filterFields.value.unassigned = false
})

watch(() => filterFields.value.ir_first_unqualified_year, (ir_first_unqualified_year) => {
    if (ir_first_unqualified_year !== "") filterFields.value.has_ir = true
})
</script>

<style>
@import "@/assets/admin-page.css";
@import "tabulator-tables";
</style>
