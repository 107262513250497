<template>
    <div class="actions-description">
        You may attach one document at a time using the form below
    </div>
    <div class="actions-form">
        <form @submit.prevent="submitDocumentForm()" v-if="formDocument">
            <div class="row p-2">
                <div class="col-3">
                    Document Name <span class="required">*</span>
                </div>
                <div class="col-7">
                    <input id="candidate-document-name" type="text" maxlength="255" v-model="formDocument.document_name"
                        class="cbFormTextField cbFormTextField-xl" required>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-3">
                    Link
                </div>
                <div class="col-7">
                    <input id="candidate-document-link" type="url" maxlength="2048" v-model="formDocument.link"
                        pattern="http(s)?://.+" class="cbFormTextField cbFormTextField-xl">
                </div>
            </div>
            <div class="row p-2">
                <div class="col-3">
                    Upload
                </div>
                <div class="col-7">
                    <input id="candidate-document-file-input" type="file" ref="fileInput"
                        class="cbFormTextField cbFormTextField-xl" @change="handleFileInput">
                </div>
            </div>
            <div class="row p-2">
                <div class="col-3">
                    Note
                </div>
                <div class="col-7">
                    <textarea id="candidate-document-notes" v-model="formDocument.notes" :maxlength="MAX_NOTE_LENGTH"
                        class="textwrapper"></textarea>
                </div>
            </div>
            <div class="row p-2">
                <div class="offset-3 col-7 d-flex">
                    <loading-icon v-if="formLoading" />
                    <button class="searchButton" type="submit" :disabled="formLoading">Submit</button>
                </div>
            </div>
        </form>

        <div class="mt-2" id="candidate-document-list"></div>
    </div>

    <ModalDialog v-if="showDeleteDocumentDialog" title="Delete Document" :close="() => { }">
        <div id="delete-document">
            Are you sure you want to delete this document?
            <br>
            <br>
            This action cannot be undone.
        </div>
        <template #footer>
            <loading-icon v-if="deleteDocumentLoading" />
            <button @click="deleteDocument()" :disabled="deleteDocumentLoading"
                class="searchButton removeButton">Confirm</button>
            <button @click="closeDeleteDocumentDialog()" class="searchButton clearButton">Close</button>
        </template>
    </ModalDialog>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted, watch } from "vue"
import type { CandidateFormDocuments, CandidateDocumentList } from "@/helpers/interface/candidates"
import { MAX_NOTE_LENGTH, getApiErrorMessage } from "@/helpers/common";
import { useAPI } from "@/helpers/services/api";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import { useRoute } from "vue-router"
import { toast } from "@/helpers/toast";
import type { AxiosError } from "axios";
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import ModalDialog from "@/components/Shared/ModalDialog.vue";
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import type { ColumnDefinition, CellComponent } from "tabulator-tables";
import type { TabulatorFull as Tabulator } from 'tabulator-tables'

const route = useRoute()
const api = useAPI()

const tru_id = route?.params?.tru_id as string
const formLoading = ref(false)
const deleteDocumentLoading = ref(false)
const fetchDocumentListLoading = ref(false)
const fileInput = ref<HTMLInputElement | null>(null);
const storeCandidateDetails = useCandidateDetailsStore();
const showDeleteDocumentDialog = ref<boolean>(false)
const selectedDeleteDocument = ref<string>("")
const formDocumentInitialState = {
    document_name: "",
    link: "",
    file: null,
    notes: "",
}

let tabulator: Tabulator | null;
const tableColumns = ref<ColumnDefinition[]>([
    {
        title: "Document Name",
        field: "document_name",
        width: "22vh",
    },
    {
        title: "Link",
        field: "link",
        width: "13vh",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateDocumentList
            if (data.link) return `<a href="${data.link}" target="_blank">Link</a>`
            return "-"
        }
    },
    {
        title: "Note",
        field: "notes",
        width: "18vh",
    },
    {
        title: "File",
        field: "created_at",
        width: "17vh",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateDocumentList
            if (data.download_url) return `<a href="${data.download_url}" target="_blank">Download</a>`
            return "-"
        }
    },
    {
        title: "&nbsp;",
        field: "updated_at",
        width: "25vh",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateDocumentList
            const actionParent = document.createElement("div")
            const documentID = data.id

            const removeButton = document.createElement("a")
            removeButton.innerHTML = "Delete"
            removeButton.classList.add("searchButton", "removeButton", "text-white")
            removeButton.setAttribute("id", `remove-btn-${documentID}`)
            removeButton.href = "javascript:;"
            removeButton.addEventListener("click", () => {
                const id = `remove-btn-loading-${documentID}`

                const removeButtonLoading = document.createElement("div")
                removeButtonLoading.classList.add("remove-btn")
                removeButtonLoading.classList.add("d-inline")
                removeButtonLoading.classList.add("d-none")
                removeButtonLoading.setAttribute("id", id)
                removeButtonLoading.innerHTML = '<span class="fa fa-spinner fa-spin"></span>'

                if (!document.getElementById(id)) actionParent.append(removeButtonLoading)
                showDeleteDocumentDialogConfirmation(documentID)

            })
            actionParent.append(removeButton)
            return actionParent
        },
    }
])

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

const formDocument = ref<CandidateFormDocuments>({
    ...formDocumentInitialState
})

const documentList = computed(() => (storeCandidateDetails.getDocumentList))

const handleFileInput = (event: any) => {
    const file = event.target.files[0]
    formDocument.value.file = file
}

const clearFileInput = () => {
    if (fileInput.value) {
        fileInput.value.type = ''; // Change the type of the file input
        fileInput.value.type = 'file'; // Restore the type to clear the file attachment
    }
};

const submitDocumentForm = async () => {
    const formData = new FormData()

    if (!formDocument.value.file && !formDocument.value.link) {
        toast.error("Please provide either a Link or File attachment")
        return
    }

    formData.append('file', formDocument.value.file || "")
    formData.append('document_name', formDocument.value.document_name)
    formData.append('link', formDocument.value.link || "")
    formData.append('notes', formDocument.value.notes || "")

    formLoading.value = true

    await api.post(`/taxroll/upload/${tru_id}`, formData)
        .then(async () => {
            await storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
            await storeCandidateDetails.fetchHistory(tru_id as string, loadFailureHandler)
            toast.success("New document file added")
            formDocument.value = { ...formDocumentInitialState }
            clearFileInput()
        })
        .catch((error: AxiosError) => {
            toast.error(getApiErrorMessage(error))
        })
        .finally(() => {
            formLoading.value = false;
        })
}

const deleteDocument = async () => {
    deleteDocumentLoading.value = true
    try {
        await storeCandidateDetails.deleteDocument(selectedDeleteDocument.value)
        await storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
        toast.success("Document has been removed")
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }
    deleteDocumentLoading.value = false
    closeDeleteDocumentDialog()
}

const showDeleteDocumentDialogConfirmation = (documentId: string) => {
    showDeleteDocumentDialog.value = true
    selectedDeleteDocument.value = documentId
}

const closeDeleteDocumentDialog = () => {
    showDeleteDocumentDialog.value = false
    selectedDeleteDocument.value = ""
}

const buildTabulator = async (isLoading: boolean) => {
    tabulator = await createTabulator("#candidate-document-list", {
        data: documentList.value,
        placeholder: "Any documents attached will appear below",
        columns: tableColumns.value,
        initialSort: [
            { column: "document_name", dir: "asc" },
        ]
    }, isLoading)
}

onMounted(async () => {
    await storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
    await buildTabulator(fetchDocumentListLoading.value)
})

onUnmounted(() => destroyTabulator(tabulator))

const isLoading = computed(() => {
    return fetchDocumentListLoading.value || deleteDocumentLoading.value || formLoading.value;
});

watch(() => isLoading.value, async (isLoading) => {
    await buildTabulator(isLoading)
})

</script>

<style>
@import "@/assets/candidate-details.css";
</style>
